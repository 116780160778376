import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
// import Link from '@mui/material/Link';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, grey, yellow } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useConfirmation } from "../services/ConfirmationService";
import { TSignedInUserWithSetUser, TSignedInUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import UserProfile from "./UserProfile";
import ChangePasswordForm from "./ChangePasswordForm";
import { Stack } from "@mui/material";
import InstituteLogo from "./InstituteLogo";
import SiteLogo from "./SiteLogo";

const pages = [
  { title: "About Us", path: "/aboutus" },
  { title: "Learn more", path: "/learn-more" },
  { title: "Documentation", path: "/documentation" },
  { title: "Contact us", path: "/contactus" },
];
const userMenus = ["Profile", "Change password", "Sign out"];

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const confirm = useConfirmation();
  const [userProfileOpen, setUserProfileOpen] = React.useState(false);
  const [changePasswordFormOpen, setChangePasswordFormOpen] =
    React.useState(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavMenu = (path: string) => {
    console.log(`navigate to ${path}`);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenu = (menu: string) => {
    if (menu === "Profile") {
      handleCloseUserMenu();
      setUserProfileOpen(true);
    } else if (menu === "Change password") {
      setChangePasswordFormOpen(true);
      handleCloseUserMenu();
    } else if (menu === "Sign out") {
      handleCloseUserMenu();
      confirm({
        variant: "danger",
        title: "Sign out",
        description: "Are you sure to sign out?",
        agreeButtonText: "Yes, go ahead to sign out",
        cancelButtonText: "No, stay signed in",
      }).then(() => {
        signedInUserWithSetUser?.setUser(null);
      });
    }
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: blue[50] }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <InstituteLogo isBanner={true} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link
              to="/home"
              style={{
                textDecoration: "none",
                color: grey[900],
              }}
            >
              <SiteLogo isBanner={true} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={handleOpenNavMenu} sx={{ color: grey[900] }}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link
                  to={page.path}
                  key={page.title}
                  style={{
                    textDecoration: "none",
                    color: grey[900],
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: 8,
                  }}
                >
                  <MenuItem>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: "100%",
              mr: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Link
              to="/home"
              style={{
                textDecoration: "none",
                color: grey[900],
              }}
            >
              <SiteLogo isBanner={true} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Link
                to={page.path}
                key={page.title}
                style={{
                  textDecoration: "none",
                  color: grey[900],
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 8,
                }}
              >
                {page.title}
              </Link>
            ))}
          </Box>

          {!signedInUserWithSetUser?.user?.username && (
            <Box sx={{ flexGrow: 0 }}>
              <Stack flexDirection="row" alignItems="center">
                <Button onClick={handleSignIn}>Sign in</Button>
                <Typography color="primary">/</Typography>
                <Button size="small" onClick={handleSignUp}>
                  Sign up
                </Button>
              </Stack>
            </Box>
          )}

          {signedInUserWithSetUser?.user?.username && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip
                title={`Logged in as ${
                  signedInUserWithSetUser?.user?.username ?? "-"
                }`}
              >
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={signedInUserWithSetUser?.user?.username}>
                    {signedInUserWithSetUser.user.username[0].toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userMenus.map((item) => (
                  <MenuItem key={item} onClick={() => handleUserMenu(item)}>
                    <Typography textAlign="center">{item}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
      {signedInUserWithSetUser?.user && (
        <UserProfile
          open={userProfileOpen}
          onClose={() => setUserProfileOpen(false)}
        />
      )}
      {signedInUserWithSetUser?.user && (
        <ChangePasswordForm
          open={changePasswordFormOpen}
          onClose={() => setChangePasswordFormOpen(false)}
        />
      )}
    </AppBar>
  );
};
export default ResponsiveAppBar;
