const jsPsychPluginPrototypes: {
  [key: string]: any;
}[] = [
    {
      type: "jsPsychMediaHtmlKeyboardResponse",
      responseType: 'keyboard',
      description: <span>Display <b>media</b> (image/GIF/audio/video) and wait for <b>keyboard</b> response</span>,
      attributes: {
        stimulus: {
          label: "Media as Stimulus",
          description: "Choose an item from your uploaded media",
          type: "media",
          mediaType: ["video", "audio", "image"],
          renderAsHTML: true,
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        choices: {
          label: "Choices",
          description: `Keys allowed as responses, e.g., 'a', 'q', ' ', 'Enter', 'ArrowDown. If "NO KEYS" is chosen, please specify the task unit duration; otherwise, the task unit has no way to end.`,
          placeHolder: "Enter keys accepted, one key on one line.",
          type: "string[]",
          defaultValue: "NO_KEYS",
          specialValues: [
            {
              value: "NO_KEYS",
              label: "No keys"
            },
            {
              value: "ALL_KEYS",
              label: "All keys"
            },
            {
              value: "SOME_KEYS",
              label: "Specify some keys",
              useInputValue: true
            },
          ]
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        stimulus_duration: {
          type: "number",
          defaultValue: null,
          label: "Stimulus duration",
          description: "How long to show the stimulus for in milliseconds. If the value is null, then the stimulus will keep being shown during this task unit.",
          unit: "milliseconds"
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        response_ends_trial: {
          label: "Whether response ends the task unit",
          description: "If true, then the task unit will end whenever the subject makes a response (assuming they make their response before the cutoff specified by the task unit duration parameter). If false, then the task unit will continue until the value for task unit duration is reached. You can set this parameter to false to force the subject to view a stimulus for a fixed amount of time, even if they respond before the time is complete.",
          type: "boolean",
          defaultValue: true
        },
      }
    },
    {
      type: "jsPsychHtmlKeyboardResponse",
      responseType: 'keyboard',
      description: <span>Display <b>text/HTML</b> content and wait for <b>keyboard</b> response</span>,
      attributes: {
        stimulus: {
          label: "Text / HTML codes as stimulus",
          placeHolder: "e.g. <h1>Hello</h1>",
          description: `Text (e.g. I am red) to be displayed or HTML codes (e.g. <p style="color:blue;">I am red</p>) to be rendered.`,
          type: "string"
        },
        choices: {
          label: "Choices",
          description: `Keys allowed as responses, e.g., 'a', 'q', ' ', 'Enter', 'ArrowDown. If "NO KEYS" is chosen, please specify the task unit duration; otherwise, the task unit has no way to end.`,
          placeHolder: "Enter keys accepted, one key on one line.",
          type: "string[]",
          defaultValue: "NO_KEYS",
          specialValues: [
            {
              value: "NO_KEYS",
              label: "No keys"
            },
            {
              value: "ALL_KEYS",
              label: "All keys"
            },
            {
              value: "SOME_KEYS",
              label: "Specify some keys",
              useInputValue: true
            },
          ]
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        stimulus_duration: {
          type: "number",
          defaultValue: null,
          label: "Stimulus duration",
          description: "How long to show the stimulus for in milliseconds. If the value is null, then the stimulus will keep being shown during this task unit.",
          unit: "milliseconds"
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        response_ends_trial: {
          label: "Whether response ends the task unit",
          description: "If true, then the task unit will end whenever the subject makes a response (assuming they make their response before the cutoff specified by the task unit duration parameter). If false, then the task unit will continue until the value for task unit duration is reached. You can set this parameter to false to force the subject to view a stimulus for a fixed amount of time, even if they respond before the time is complete.",
          type: "boolean",
          defaultValue: true
        },
      }
    },
    {
      type: "jsPsychImageKeyboardResponse",
      responseType: 'keyboard',
      description: <span>Display <b>image</b> (width & height adjustable) and wait for <b>keyboard</b> response</span>,
      attributes: {
        stimulus: {
          label: "Image stimulus",
          description: "Specify the image to be displayed as the stimulus",
          type: "media",
          mediaType: ["image"],
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        choices: {
          label: "Choices",
          description: `Keys allowed as responses, e.g., 'a', 'q', ' ', 'Enter', 'ArrowDown. If "NO KEYS" is chosen, please specify the task unit duration; otherwise, the task unit has no way to end.`,
          placeHolder: "Enter keys accepted, one key on one line.",
          type: "string[]",
          defaultValue: "NO_KEYS",
          specialValues: [
            {
              value: "NO_KEYS",
              label: "No keys"
            },
            {
              value: "ALL_KEYS",
              label: "All keys"
            },
            {
              value: "SOME_KEYS",
              label: "Specify some keys",
              useInputValue: true
            },
          ]
        },
        stimulus_width: {
          type: "number",
          defaultValue: null,
          label: "Image width",
          description: "Set the width of the image in pixels",
          unit: "pixels"
        },
        stimulus_height: {
          type: "number",
          defaultValue: null,
          label: "Image height",
          description: "Set the height of the image in pixels",
          unit: "pixels"
        },
        maintain_aspect_ratio: {
          label: "Maintain aspect ratio",
          description: "If setting only the width or only the height and this parameter is true, then the other dimension will be scaled to maintain the image's aspect ratio.",
          type: "boolean",
          defaultValue: true
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        stimulus_duration: {
          type: "number",
          defaultValue: null,
          label: "Stimulus duration",
          description: "How long to show the stimulus for in milliseconds. If the value is null, then the stimulus will keep being shown during this task unit.",
          unit: "milliseconds"
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        response_ends_trial: {
          label: "Whether response ends the task unit",
          description: "If true, then the task unit will end whenever the subject makes a response (assuming they make their response before the cutoff specified by the task unit duration parameter). If false, then the task unit will continue until the value for task unit duration is reached. You can set this parameter to false to force the subject to view a stimulus for a fixed amount of time, even if they respond before the time is complete.",
          type: "boolean",
          defaultValue: true
        },
      }
    },
    {
      type: "jsPsychVideoKeyboardResponse",
      responseType: 'keyboard',
      description: <span>Play <b>video</b> and wait for <b>keyboard</b> response (can disallow response until video is finished)</span>,
      attributes: {
        stimulus: {
          label: "Video stimulus",
          description: "Specify the video to be played as the stimulus",
          type: "media[]",
          mediaType: ["video"],
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        autoplay: {
          type: "boolean",
          defaultValue: true,
          hidden: true
        },
        controls: {
          type: "boolean",
          defaultValue: false,
          hidden: true
        },
        choices: {
          label: "Choices",
          description: `Keys allowed as responses, e.g., 'a', 'q', ' ', 'Enter', 'ArrowDown. If "NO KEYS" is chosen, please specify the task unit duration; otherwise, the task unit has no way to end.`,
          placeHolder: "Enter keys accepted, one key on one line.",
          type: "string[]",
          defaultValue: "NO_KEYS",
          specialValues: [
            {
              value: "NO_KEYS",
              label: "No keys"
            },
            {
              value: "ALL_KEYS",
              label: "All keys"
            },
            {
              value: "SOME_KEYS",
              label: "Specify some keys",
              useInputValue: true
            },
          ]
        },
        trial_ends_after_video: {
          label: "Task unit ends after video",
          description: "If true, then the task unit will end as soon as the video file finishes playing.",
          type: "boolean",
          defaultValue: true,
          hidden: false
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, then the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        response_allowed_while_playing: {
          label: "Response allowed while playing",
          description: "If enabled, then responses are allowed while the media is playing. If disabled, then the media must finish playing before a keyboard response is accepted.",
          type: "boolean",
          defaultValue: false
        },
      }
    },
    {
      type: "jsPsychAudioKeyboardResponse",
      responseType: 'keyboard',
      description: <span>Play <b>audio</b> and wait for <b>keyboard</b> response (can disallow response until audio is finished)</span>,
      attributes: {
        stimulus: {
          label: "Audio stimulus",
          description: "Specify the audio to be played as the stimulus",
          type: "media",
          mediaType: ["audio"],
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        trial_ends_after_audio: {
          label: "Ends after audio",
          description: "If enabled, then the task unit will end as soon as the audio file finishes playing.",
          type: "boolean",
          defaultValue: false,
          hidden: false
        },
        choices: {
          label: "Choices",
          description: `Keys allowed as responses, e.g., 'a', 'q', ' ', 'Enter', 'ArrowDown. If "NO KEYS" is chosen, please specify the task unit duration; otherwise, the task unit has no way to end.`,
          placeHolder: "Enter keys accepted, one key on one line.",
          type: "string[]",
          defaultValue: "NO_KEYS",
          specialValues: [
            {
              value: "NO_KEYS",
              label: "No keys"
            },
            {
              value: "ALL_KEYS",
              label: "All keys"
            },
            {
              value: "SOME_KEYS",
              label: "Specify some keys",
              useInputValue: true
            },
          ]
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        response_allowed_while_playing: {
          label: "Response allowed while playing",
          description: "If enabled, then responses are allowed while the audio is playing. If disabled, then the audio must finish playing before a keyboard response is accepted.",
          type: "boolean",
          defaultValue: false
        },
      }
    },
    {
      type: "jsPsychMediaHtmlButtonResponse",
      responseType: 'button',
      description: <span>Display <b>media</b> (image/GIF/audio/video) and wait for <b>button</b> response</span>,
      attributes: {
        stimulus: {
          label: "Media as Stimulus",
          description: "Choose an item from your uploaded media",
          type: "media",
          mediaType: ["video", "audio", "image"],
          renderAsHTML: true,
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        choices: {
          label: "Choices",
          description: "Labels for the buttons. One key on one line.",
          placeHolder: "Apple\nOrange",
          type: "string[]",
          defaultValue: []
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        stimulus_duration: {
          type: "number",
          defaultValue: null,
          label: "Stimulus duration",
          description: "How long to show the stimulus for in milliseconds. If the value is null, then the stimulus will keep being shown during this task unit.",
          unit: "milliseconds"
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        margin_vertical: {
          type: "string",
          label: "Vertical margin",
          description: "Vertical margin of the button(s).",
          placeHolder: "e.g. 0px",
          defaultValue: "0px",
          hidden: true
        },
        margin_horizontal: {
          type: "string",
          label: "Horizontal margin",
          description: "Horizontal margin of the button(s).",
          placeHolder: "e.g. 8px",
          defaultValue: "8px",
          hidden: true
        },
        response_ends_trial: {
          label: "Whether response ends the task unit",
          description: "If true, then the task unit will end whenever the subject makes a response (assuming they make their response before the cutoff specified by the task unit duration parameter). If false, then the task unit will continue until the value for task unit duration is reached. You can set this parameter to false to force the subject to view a stimulus for a fixed amount of time, even if they respond before the time is complete.",
          type: "boolean",
          defaultValue: true
        },
      }
    },
    {
      type: "jsPsychHtmlButtonResponse",
      responseType: 'button',
      description: <span>Display <b>text/HTML</b> content and wait for <b>button</b> response</span>,
      attributes: {
        stimulus: {
          label: "Text / HTML codes as stimulus",
          placeHolder: "e.g. <h1>Hello</h1>",
          description: `Text (e.g. I am red) to be displayed or HTML codes (e.g. <p style="color:blue;">I am red</p>) to be rendered.`,
          type: "string"
        },
        choices: {
          label: "Choices",
          description: "Labels for the buttons. One key on one line.",
          placeHolder: "Apple\nOrange",
          type: "string[]",
          defaultValue: []
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        stimulus_duration: {
          type: "number",
          defaultValue: null,
          label: "Stimulus duration",
          description: "How long to show the stimulus for in milliseconds. If the value is null, then the stimulus will keep being shown during this task unit.",
          unit: "milliseconds"
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        margin_vertical: {
          type: "string",
          label: "Vertical margin",
          description: "Vertical margin of the button(s).",
          placeHolder: "e.g. 0px",
          defaultValue: "0px",
          hidden: true
        },
        margin_horizontal: {
          type: "string",
          label: "Horizontal margin",
          description: "Horizontal margin of the button(s).",
          placeHolder: "e.g. 8px",
          defaultValue: "8px",
          hidden: true
        },
        response_ends_trial: {
          label: "Whether response ends the task unit",
          description: "If true, then the task unit will end whenever the subject makes a response (assuming they make their response before the cutoff specified by the task unit duration parameter). If false, then the task unit will continue until the value for task unit duration is reached. You can set this parameter to false to force the subject to view a stimulus for a fixed amount of time, even if they respond before the time is complete.",
          type: "boolean",
          defaultValue: true
        },
      }
    },
    {
      type: "jsPsychImageButtonResponse",
      responseType: 'button',
      description: <span>Display <b>image</b> (width & height adjustable) and wait for <b>button</b> response</span>,
      attributes: {
        stimulus: {
          label: "Image stimulus",
          description: "Specify the image to be displayed as the stimulus",
          type: "media",
          mediaType: ["image"],
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        choices: {
          label: "Choices",
          description: "Labels for the buttons. One key on one line.",
          placeHolder: "Apple\nOrange",
          type: "string[]",
          defaultValue: []
        },
        stimulus_width: {
          type: "number",
          defaultValue: null,
          label: "Image width",
          description: "Set the width of the image in pixels",
          unit: "pixels"
        },
        stimulus_height: {
          type: "number",
          defaultValue: null,
          label: "Image height",
          description: "Set the height of the image in pixels",
          unit: "pixels"
        },
        maintain_aspect_ratio: {
          label: "Maintain aspect ratio",
          description: "If setting only the width or only the height and this parameter is true, then the other dimension will be scaled to maintain the image's aspect ratio.",
          type: "boolean",
          defaultValue: true
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        stimulus_duration: {
          type: "number",
          defaultValue: null,
          label: "Stimulus duration",
          description: "How long to show the stimulus for in milliseconds. If the value is null, then the stimulus will keep being shown during this task unit.",
          unit: "milliseconds"
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        margin_vertical: {
          type: "string",
          label: "Vertical margin",
          description: "Vertical margin of the button(s).",
          placeHolder: "e.g. 0px",
          defaultValue: "0px",
          hidden: true
        },
        margin_horizontal: {
          type: "string",
          label: "Horizontal margin",
          description: "Horizontal margin of the button(s).",
          placeHolder: "e.g. 8px",
          defaultValue: "8px",
          hidden: true
        },
        response_ends_trial: {
          label: "Whether response ends the task unit",
          description: "If true, then the task unit will end whenever the subject makes a response (assuming they make their response before the cutoff specified by the task unit duration parameter). If false, then the task unit will continue until the value for task unit duration is reached. You can set this parameter to false to force the subject to view a stimulus for a fixed amount of time, even if they respond before the time is complete.",
          type: "boolean",
          defaultValue: true
        },
      }
    },
    {
      type: "jsPsychVideoButtonResponse",
      responseType: 'button',
      description: <span>Play <b>video</b> and wait for <b>button</b> response (can disallow response until video is finished)</span>,
      attributes: {
        stimulus: {
          label: "Video stimulus",
          description: "Specify the video to be played as the stimulus",
          type: "media[]",
          mediaType: ["video"],
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        autoplay: { type: "boolean", defaultValue: true, hidden: true },
        controls: { type: "boolean", defaultValue: false, hidden: true },
        choices: {
          label: "Choices",
          description: "Labels for the buttons. One key on one line.",
          placeHolder: "Apple\nOrange",
          type: "string[]",
          defaultValue: []
        },
        trial_ends_after_video: {
          label: "Task unit ends after video",
          description: "If true, then the task unit will end as soon as the video file finishes playing.",
          type: "boolean",
          defaultValue: true,
          hidden: false
        },
        response_allowed_while_playing: {
          label: "Response allowed while playing",
          description: "If enabled, then responses are allowed while the media is playing. If disabled, then the media must finish playing before a button response is accepted.",
          type: "boolean",
          defaultValue: false
        },
      }
    },
    {
      type: "jsPsychAudioButtonResponse",
      responseType: 'button',
      description: <span>Play <b>audio</b> and wait for <b>button</b> response (can disallow response until audio is finished)</span>,
      attributes: {
        stimulus: {
          label: "Audio stimulus",
          description: "Specify the audio to be played as the stimulus",
          type: "media",
          mediaType: ["audio"],
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        trial_ends_after_audio: {
          label: "Ends after audio",
          description: "If enabled, then the task unit will end as soon as the audio file finishes playing.",
          type: "boolean",
          defaultValue: false,
          hidden: false
        },
        choices: {
          label: "Choices",
          description: "Labels for the buttons. One key on one line.",
          placeHolder: "Apple\nOrange",
          type: "string[]",
          defaultValue: []
        },
        prompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus. The intention is that it can be used to provide a reminder about the action the subject is supposed to take (e.g., which key to press).",
          placeHolder: "e.g. <span>I'm done</span>",
          defaultValue: ""
        },
        trial_duration: {
          type: "number",
          defaultValue: null,
          label: "Task unit duration",
          description: "How long to wait for the subject to make a response before ending the task unit in milliseconds. If the subject fails to make a response before this timer is reached, the subject's response will be recorded as null for the task unit and the task unit will end. If the value of this parameter is null, the task unit will wait for a response indefinitely.",
          unit: "milliseconds"
        },
        response_allowed_while_playing: {
          label: "Response allowed while playing",
          description: "If enabled, then responses are allowed while the audio is playing. If disabled, then the audio must finish playing before a keyboard response is accepted.",
          type: "boolean",
          defaultValue: false
        },
      }
    },
    {
      type: "jsPsychHtmlAudioResponse",
      responseType: 'audio',
      description: <span>Display <b>media</b> (image/GIF/audio/video) and wait for <b>audio</b> response</span>,
      attributes: {
        stimulus: {
          label: "Media as Stimulus",
          description: "Choose an item from your uploaded media",
          type: "media",
          mediaType: ["video", "audio", "image"],
          renderAsHTML: true,
          mediaKey: "stimulusCode"
        },
        mediaWidth: {
          type: "number",
          // defaultValue: 800,
          label: "Width for image/video rendering",
          unit: "pixels",
          supportedMediaTypes: ["video", "image"],
          stimulusKey: "stimulus",
          // property: "width",
          reRenderStimulus: true
        },
        mediaHeight: {
          type: "number",
          // defaultValue: 600,
          label: "Height for image/video rendering",
          unit: "pixels",
          supportedMediaTypes: ["video", "image"],
          stimulusKey: "stimulus",
          // property: "height",
          reRenderStimulus: true
        },
        mediaPrompt: {
          type: "string",
          label: "Prompt",
          description: "The string can contain HTML markup. Any content here will be displayed below the stimulus.",
          defaultValue: "",
          // supportedMediaTypes: ["video", "image", "audio"],
          stimulusKey: "stimulus",
          reRenderStimulus: true
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        save_audio_url: {
          type: "boolean",
          defaultValue: true,
          hidden: true
        },
        show_done_button: {
          label: "Show done button",
          description: "Whether to show a button on the screen that the participant can click to finish the recording. If shown, specify its label below.",
          type: "boolean",
          defaultValue: true
        },
        done_button_label: {
          label: "Done button label",
          description: "The label for the done button.",
          type: "string",
          defaultValue: "Done",
          placeHolder: "e.g. 下一題",
        },
        recording_duration: {
          type: "number",
          defaultValue: 60000,
          label: "Max recording duration",
          description: "The maximum length of the recording, in milliseconds.",
          unit: "milliseconds"
        },
        allow_playback: {
          label: "Allow playback",
          description: "Whether to allow the participant to listen to their recording and decide whether to rerecord or not. Specify the two labels below if playback is allowed.",
          type: "boolean",
          defaultValue: false,
          hidden: false
        },
        record_again_button_label: {
          label: "Record again button label",
          description: "The label for the 'Record again' button.",
          type: "string",
          defaultValue: 'Record again',
          placeHolder: "e.g. 再錄一遍",
        },
        accept_button_label: {
          label: "Continue button label",
          description: "The label for the 'Continue' button.",
          type: "string",
          defaultValue: 'Continue',
          placeHolder: "e.g. 可以了",
        },
      }
    },
    {
      type: "jsPsychHtmlAudioResponseText",
      responseType: 'audio',
      description: <span>Display <b>text/HTML</b> content and wait for <b>audio</b> response</span>,
      attributes: {
        stimulus: {
          label: "Text / HTML codes as stimulus",
          placeHolder: "e.g. <h1>Hello</h1>",
          description: `Text (e.g. I am red) to be displayed or HTML codes (e.g. <p style="color:blue;">I am red</p>) to be rendered.`,
          type: "string"
        },
        save_audio_url: {
          type: "boolean",
          defaultValue: true,
          hidden: true
        },
        show_done_button: {
          label: "Show done button",
          description: "Whether to show a button on the screen that the participant can click to finish the recording. If shown, specify its label below.",
          type: "boolean",
          defaultValue: true
        },
        done_button_label: {
          label: "Done button label",
          description: "The label for the done button.",
          type: "string",
          defaultValue: "Done",
          placeHolder: "e.g. 下一題",
        },
        recording_duration: {
          type: "number",
          defaultValue: 60000,
          label: "Max recording duration",
          description: "The maximum length of the recording, in milliseconds.",
          unit: "milliseconds"
        },
        allow_playback: {
          label: "Allow playback",
          description: "Whether to allow the participant to listen to their recording and decide whether to rerecord or not. Specify the two labels below if playback is allowed.",
          type: "boolean",
          defaultValue: false,
          hidden: false
        },
        record_again_button_label: {
          label: "Record again button label",
          description: "The label for the 'Record again' button.",
          type: "string",
          defaultValue: 'Record again',
          placeHolder: "e.g. 再錄一遍",
        },
        accept_button_label: {
          label: "Continue button label",
          description: "The label for the 'Continue' button.",
          type: "string",
          defaultValue: 'Continue',
          placeHolder: "e.g. 可以了",
        }
      }
    },
    {
      type: 'jsPsychSurveyText',
      responseType: 'text',
      description: <span>Display <b>question with optional media</b> (image/GIF/audio/video) and wait for free <b>text</b> response</span>,
      attributes: {
        preamble: {
          label: "Media as stimulus (optional)",
          description: "The media item as stimulus.",
          type: "media",
          mediaType: ["video", "audio", "image"],
          renderAsHTML: true,
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        questions: {
          label: 'Questions',
          placeHolder: 'Enter a question',
          type: "questions",
          defaultValue: [{ prompt: '' }],
          hidden: false
        },
        button_label: {
          label: "Done button label",
          description: "The label for the done button.",
          type: "string",
          defaultValue: "Done",
          placeHolder: "e.g. 下一題",
        }
      }
    },
    {
      type: 'jsPsychSurveyMultiChoice',
      responseType: 'button',
      description: <span>Display <b>question with optional media</b> (image/GIF/audio/video) and wait for <b>button</b> response</span>,
      attributes: {
        preamble: {
          label: "Media as stimulus",
          description: "The media item as stimulus.",
          type: "media",
          mediaType: ["video", "audio", "image"],
          renderAsHTML: true,
          mediaKey: "stimulusCode"
        },
        stimulusCode: {
          type: "string",
          hidden: true
        },
        questions: {
          label: 'Questions',
          placeHolder: 'Enter a question',
          type: "multichoice",
          defaultValue: [{ prompt: '' }],
          hidden: false
        },
        button_label: {
          label: "Done button label",
          description: "The label for the done button.",
          type: "string",
          placeHolder: "e.g. 下一題",
        }
      }
    },
  ]

export default jsPsychPluginPrototypes