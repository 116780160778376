import React from "react";
// import logo from "./logo.svg";
// import "./App.css";
// import { ExperimentWindow } from "jspsych-react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import { useQuery } from "react-query";
import { Stack } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RateReviewIcon from "@mui/icons-material/RateReview";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { orange } from "@mui/material/colors";
import { ParticipantContext } from "../contexts/ParticipantContext";
import { TParticipantWithRefetch } from "../resources/types";
import { api } from "../resources/config";
import EdUHKLogo from "../assets/images/EdUHK_Signature_RGB.png";
import DoldImage from "../assets/images/dold.gif";
import LmlLogo from "../assets/images/lml-logo.png";
import CrllsLogo from "../assets/images/crlls-logo.png";
import { renderTerm } from "../resources/lexicon";

import {
  Paper,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { generateRandomSuffix } from "../libraries/helper";
import InstituteLogo from "../components/InstituteLogo";
import SiteLogo from "../components/SiteLogo";

function App() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchInspected, setUrlSearchInspected] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [task, setTask] = React.useState("");
  const participantWithRefetch: TParticipantWithRefetch | null =
    React.useContext(ParticipantContext);
  // const [data, setData] = React.useState<any>(null)
  const [startClicked, setStartClicked] = React.useState(false);
  // const [done, setDone] = React.useState(false)
  // const [data, setData] = React.useState<any>({})
  // const [startClicked, setStartClicked] = React.useState(true)
  // const [done, setDone] = React.useState(true)

  React.useEffect(() => {
    if (!urlSearchInspected) {
      let email = (searchParams.get("id") as string) ?? "";
      const task = (searchParams.get("task") as string) ?? "";

      if (email) {
        email = generateRandomSuffix(email);
        setEmail(email);
      } else if (task) {
        email = generateRandomSuffix(task);
        setEmail(email);
      }
      if (task) {
        setTask(task);
      }
      // query.delete("email")
      // query.delete("task")
      setUrlSearchInspected(true);
      participantWithRefetch?.changeEmail(email);
    }
  }, [participantWithRefetch, searchParams, urlSearchInspected]);

  // const { isLoading, isFetching, error, data: participantAudioData, refetch } = useQuery(
  //   ["participant", participantWithRefetch?.user?.participantId],
  //   () =>
  //     fetch(api.getParticipantAudio(participantWithRefetch?.user?.participantId ?? ''), {
  //       method: "GET",
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //     }).then((res) => res.json()),
  //   {
  //     enabled: !!participantWithRefetch?.user?.participantId
  //   }
  // );

  const { data: taskData } = useQuery(
    ["task", task],
    () =>
      fetch(api.getTaskByCode({ taskCode: task ?? "" }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }).then((res) => {
        // console.log(`status code = ${res.status}, type = ${typeof res.status}`)
        if (res.status >= 200 && res.status < 400) {
          return res.json();
        } else {
          throw new Error("Error retrieving task definition");
        }
      }),
    {
      enabled: !!task,
    }
  );

  React.useEffect(() => {
    if (
      participantWithRefetch?.user?.participantId &&
      participantWithRefetch?.user?.email
    ) {
      setEmail(participantWithRefetch?.user?.email);
      if (task !== "survey") {
        navigate({
          pathname: "/task",
          search: `?${createSearchParams({
            // email: participantWithRefetch?.user?.email,
            task,
          })}`,
        });
      } else {
        navigate("/survey");
      }
    }
  }, [
    navigate,
    participantWithRefetch?.user?.email,
    participantWithRefetch?.user?.participantId,
    task,
  ]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleStartClick();
    }
  };

  const handleStartClick = () => {
    email && participantWithRefetch?.changeEmail(email);
  };

  /*
  React.useEffect(
    () => {
      // navigate('/ctnCompC')
    },
    [navigate, participantWithRefetch?.user?.participantId]
  )
  */

  const handleClearEmail = () => {
    setEmail("");
    participantWithRefetch?.changeEmail("");
  };

  /*
  const handleTaskClick = (taskId: string) => {
    navigate(tasks.find(v => v.task === taskId)?.path ?? '')
  }
  */

  const handleSurveyClick = () => {
    navigate("/survey");
  };

  if (!participantWithRefetch?.user?.participantId) {
    return (
      <Box
        className="App"
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack flexDirection="row">
          <InstituteLogo />
          <SiteLogo />
        </Stack>
        <Link
          component={RouterLink}
          to="/home"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={DoldImage} alt="DOLD" style={{ height: "14vmin" }} />
        </Link>
        {/*<Typography variant="h3" sx={{ mt: 6, mb: 4, color: blue[800], letterSpacing: 4, fontWeight: 500, fontFamily: "Cinzel, serif" }}>Project MAIN</Typography>*/}
        {/*<Typography color="primary" variant="body2">Multilingual Assessment Instrument for Narratives
          <Link href={projectUrl} sx={{ ml: 1 }}>*</Link>
    </Typography>*/}
        {task && taskData && !participantWithRefetch?.user?.participantId && (
          <Box
            width={"48ch"}
            sx={{
              mt: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label={renderTerm(
                taskData?.language ?? "cantonese",
                "userIdOrEmail"
              )}
              value={email}
              disabled={!!participantWithRefetch?.fetching}
              placeholder={renderTerm(
                taskData?.language ?? "cantonese",
                "enterUserIdOrEmailNote"
              )}
              onChange={handleEmailChange}
              onKeyPress={handlePressEnter}
              InputProps={{
                style: { fontSize: "large" },
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
              autoFocus
            />
            <Button
              variant="outlined"
              disabled={!!participantWithRefetch?.fetching || !email}
              sx={{
                fontSize: "x-large",
                mt: 4,
                paddingLeft: 4,
                paddingRight: 4,
              }}
              onClick={handleStartClick}
              endIcon={
                !!participantWithRefetch?.fetching ? (
                  <CircularProgress size={16} />
                ) : (
                  <FlightTakeoffIcon
                    sx={{
                      color:
                        !!participantWithRefetch?.fetching || !email
                          ? orange[200]
                          : orange[500],
                      transform: "scale(1.6)",
                      marginLeft: 2,
                    }}
                    fontSize="large"
                  />
                )
              }
            >
              {renderTerm(taskData?.language ?? "cantonese", "letsStart")}
            </Button>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Paper className="App" elevation={0}>
        <AppBar position="sticky">
          <Toolbar variant="dense">
            <IconButton
              size="large"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={handleClearEmail}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {email}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<RateReviewIcon />}
              onClick={handleSurveyClick}
            >
              Questionnaire
            </Button>
          </Toolbar>
        </AppBar>
        {/*participantAudioData && <Box width={'80vw'} sx={{margin: 4}}>
          <TaskProgressList
            tasks={tasks}
            audioData={participantAudioData}
            onClick={handleTaskClick}
          />
    </Box>*/}
      </Paper>
    );
  }
}

export default App;
