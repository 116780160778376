import React from "react";
import TimerIcon from "@mui/icons-material/Timer";
import "./CountdownTimer.css";

const CountdownTimer = ({ targetTimestamp }: { targetTimestamp: number }) => {
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const timeLeft = Math.ceil((targetTimestamp - now) / 1000) * 1000;

    if (timeLeft > 0) {
      return {
        hours: Math.floor(timeLeft / (1000 * 60 * 60)),
        minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
      };
    }

    return { hours: 0, minutes: 0, seconds: 0 };
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
  const [previousTimeLeft, setPreviousTimeLeft] = React.useState(timeLeft);

  React.useEffect(() => {
    if (
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    )
      return;

    const timeoutId = setTimeout(() => {
      setPreviousTimeLeft(timeLeft);
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [timeLeft, targetTimestamp]);

  const formatTimeUnit = (unit: number) => String(unit).padStart(2, "0");

  if (timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) {
    return (
      <div className="countdown-container">
        <TimerIcon sx={{ color: "orange", fontSize: 16, mr: 2 }} />
        <div className="time-unit">
          <FlipCard
            current={formatTimeUnit(timeLeft.hours)}
            previous={formatTimeUnit(previousTimeLeft.hours)}
          />
        </div>
        <span className="timer">:</span>
        <div className="time-unit">
          <FlipCard
            current={formatTimeUnit(timeLeft.minutes)}
            previous={formatTimeUnit(previousTimeLeft.minutes)}
          />
        </div>
        <span className="timer">:</span>
        <div className="time-unit">
          <FlipCard
            current={formatTimeUnit(timeLeft.seconds)}
            previous={formatTimeUnit(previousTimeLeft.seconds)}
          />
        </div>
      </div>
    );
  } else {
    return null
  }
};

const FlipCard = ({
  current,
  previous,
}: {
  current: string;
  previous: string;
}) => {
  return (
    <div className="fade-card">
      <div className="fade-card-inner">
        <div
          className={`fade-card-front ${
            previous !== current ? "fade-out" : ""
          }`}
        >
          {previous}
        </div>
        <div
          className={`fade-card-back ${previous !== current ? "fade-in" : ""}`}
        >
          {current}
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
