import EdUHKLogo from "../assets/images/EdUHK_Signature_RGB.png";
import LmlLogo from "../assets/images/lml-logo.png";
import CrllsLogo from "../assets/images/crlls-logo.png";
import { Box, Stack } from "@mui/material";
import { site } from "../resources/config";
import AsiaULogo from "../assets/images/dold-tw-asia-u.png";
import React from "react";

const InstituteLogo = ({ isBanner = false }: { isBanner?: boolean }) => {
  return isBanner ? (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-around"
      sx={{ display: { xs: "none", md: "flex" } }}
      flexWrap={"nowrap"}
    >
      {site === "eduhk" ? (
        <React.Fragment>
          <Box sx={{ mr: 2 }}>
            <img src={EdUHKLogo} height={80} alt="EdUHK" />
          </Box>
          <Box sx={{ mr: 2 }}>
            <img src={CrllsLogo} height={45} alt="CRLLS" />
          </Box>
          <Box sx={{ mr: 2 }}>
            <img src={LmlLogo} height={45} alt="LML" />
          </Box>
        </React.Fragment>
      ) : (
        <Box><img
        src={AsiaULogo}
        alt="Asia University"
        height={72}
      /></Box>
      )}
    </Stack>
  ) : (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-around"
    >
      {site === "eduhk" ? (
        <React.Fragment>
          <Box sx={{ m: 2 }}>
            <img src={EdUHKLogo} alt="EdUHK" style={{ height: "9vmin" }} />
          </Box>
          <Box sx={{ m: 2 }}>
            <img src={CrllsLogo} alt="CRLLS" style={{ height: "7vmin" }} />
          </Box>
          <Box sx={{ m: 2 }}>
            <img src={LmlLogo} alt="LML" style={{ height: "7vmin" }} />
          </Box>
        </React.Fragment>
      ) : (
        <Box>
          <img
            src={AsiaULogo}
            alt="Asia University"
            style={{ height: "9vmin" }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default InstituteLogo;
