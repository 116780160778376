import React from "react";
import {
  Paper,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Chip,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  TextField,
  InputAdornment,
  Fab,
} from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MicIcon from "@mui/icons-material/Mic";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import DataCount from "../components/DataCount";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import { blue } from "@mui/material/colors";
import { Fade } from "react-awesome-reveal";
import { api, audioPath } from "../resources/config";
import { TExperiment, TTaskUnit } from "../resources/types";
import CurtainWithProgress from "../components/CurtainWithProgress";
import ExperimentCard from "../components/ExperimentCard";
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";
import ExperimentEditor from "../components/ExperimentEditor";
import { matchKeywords } from "../libraries/helper";
import ResponsiveAppBar from "../components/AppBar";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import ProjectChanger from "../components/ProjectChanger";
import ErrorDisplay from "../components/ErrorDisplay";
import pluginMap from "../libraries/pluginMap";
import TextBoxCopy from "../components/TextBoxCopy";
import ExperimentUrlGenerator from "../components/ExperimentUrlGenerator";
import SkipFlowControlIcon from "../components/SkipFlowControlIcon";
import ItemTitleInEdit from "../components/ItemTitleInEdit";

const validateExperiment = (e: TExperiment) => {
  if (!e.language) {
    throw Error("No language selected. Please select one.");
  }
};

function ExperimentPage() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [experimentForEdit, setExperimentForEdit] =
    React.useState<TExperiment | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [createNew, setCreateNew] = React.useState(false);
  const [experimentFilter, setExperimentFilter] = React.useState("");
  const [taskFilter, setTaskFilter] = React.useState("");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const snackbar = useSnackbar();
  const [isProjectChangeOpen, setIsProjectChangeOpen] = React.useState(false);
  const [experimentCodeForProjectChange, setExperimentCodeForProjectChange] =
    React.useState("");
  const [originalProjectIds, setOriginalProjectIds] = React.useState<string[]>(
    []
  );

  const projectId = React.useMemo(
    () =>
      signedInUserWithSetUser?.projects?.find(
        (v) => v.code === signedInUserWithSetUser?.projectCodeInUse
      )?._id,
    [
      signedInUserWithSetUser?.projectCodeInUse,
      signedInUserWithSetUser?.projects,
    ]
  );
  const { data: taskUnitData, refetch: refetchTaskUnitData } = useQuery(
    ["taskunit-data", projectId],
    () =>
      fetch(api.getTaskUnits({ projectId }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: experimentData,
    refetch: refetchExperimentData,
  } = useQuery(
    ["experiment-data", projectId],
    () =>
      fetch(api.getExperiments({ projectId }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  React.useEffect(() => {
    if (isError) {
      console.log(`isError: ${error}`);
      if ((error as Error).message === "Unauthorized") {
        signedInUserWithSetUser?.setUser(null);
      }
    }
  }, [isError, error, signedInUserWithSetUser]);

  const handleEdit = (taskCode: string) => {
    console.log(`handleEdit() taskCode=${taskCode}`);
    const exp = experimentData.find(
      (p: TExperiment) => p.code === taskCode
    ) as TExperiment;
    if (!exp) {
      setExperimentForEdit(null);
      return;
    }
    const cloned = {
      ...exp,
      taskUnits: [...exp.taskUnits],
      skipFlowControls: exp.skipFlowControls ? [...exp.skipFlowControls] : [],
    } as TExperiment;
    setCreateNew(false);
    setExperimentForEdit(cloned);
  };

  const handleCopy = (taskCode: string) => {
    console.log(`handleCopy() taskCode=${taskCode}`);
    const exp = experimentData.find(
      (p: TExperiment) => p.code === taskCode
    ) as TExperiment;
    if (!exp) {
      setExperimentForEdit(null);
      return;
    }
    const cloned = {
      ...exp,
      code: "",
      taskUnits: [...exp.taskUnits],
      skipFlowControls: exp.skipFlowControls ? [...exp.skipFlowControls] : [],
    } as TExperiment;
    setCreateNew(true);
    setExperimentForEdit(cloned);
  };

  const handleShare = async (taskCode: string) => {
    console.log(`handleShare() taskCode=${taskCode}`);
    const urlOrigin = document.location.origin;
    const urlPathname = document.location.pathname;
    const taskUrl = `${urlOrigin}${urlPathname}#/?task=${taskCode}`;
    await navigator.clipboard?.writeText(taskUrl);
    snackbar({
      severity: "info",
      message: `URL of the experiment copied to clipboard`,
    });
    confirm({
      variant: "info",
      title: `URL for experiment "${taskCode}"`,
      description: (
        <Box>
          <Box>
            <Typography
              variant="body1"
              sx={{ mb: 1 }}
            >{`The URL is copied to clipboard. You may also click here `}</Typography>
            <TextBoxCopy text={taskUrl}>
              <a href={taskUrl} rel="noreferrer" target={"_blank"}>
                {taskUrl}
              </a>
            </TextBoxCopy>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              An ID parameter can be appended to identify the participant
            </Typography>
            <TextBoxCopy text={`${taskUrl}&id=[participant_id]`}>
              <span
                style={{ whiteSpace: "break-spaces", wordBreak: "break-all" }}
              >
                {taskUrl}
                <span style={{ fontStyle: "italic", color: blue[600] }}>
                  {"&id=[participant_id]"}
                </span>
              </span>
            </TextBoxCopy>
          </Box>

          <Box sx={{ mt: 4 }}>
            <ExperimentUrlGenerator
              taskCode={taskCode}
              taskUrl={taskUrl}
              onChange={function (e: TExperiment): void {
                throw new Error("Function not implemented.");
              }}
            />
          </Box>
        </Box>
      ),
      agreeButtonText: "OK",
    });
  };

  const handleDelete = (taskCode: string) => {
    if (!taskCode) {
      return;
    }
    console.log(`handleDelete() taskCode=${taskCode}`);
    confirm({
      variant: "danger",
      title: `Delete experiment "${taskCode}"`,
      description: `Are you sure to delete experiment "${taskCode}"?`,
      cancelButtonText: "No",
      agreeButtonText: "Yes, go ahead to delete",
      catchOnCancel: true,
    })
      .then(() => {
        console.log(`Go ahead to delete ${taskCode}`);
        setIsProcessing(true);
        fetch(api.deleteExperiment({ projectId, taskCode }), {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Experiment "${taskCode}" is deleted successfully`,
            });
            refetchExperimentData();
          })
          .catch((error) => {
            setIsProcessing(false);
            confirm({
              variant: "error",
              title: `Error deleting experiment "${taskCode}"`,
              description: `Deletion of experiment "${taskCode}" failed: ${(
                error as Error
              )?.toString()}`,
            });
          });
      })
      .catch(() => {
        setIsProcessing(false);
        snackbar({
          severity: "info",
          message: `Deletion of experiment "${taskCode}" cancelled`,
        });
      });
  };

  const handleExperimentChange = (experiment: TExperiment) => {
    console.log(
      `handleExperimentChange(), experiment = ${JSON.stringify(experiment)}`
    );
    setExperimentForEdit(experiment);
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleSaveEdited = async () => {
    console.log(`handleSaveEdited`);
    if (!experimentForEdit) {
      return;
    }
    let needMicTesting = false;
    experimentForEdit.taskUnits.forEach((taskCode) => {
      const tu = taskUnitData?.find(
        (p: TTaskUnit) => p.code === taskCode
      ) as TTaskUnit;
      if (!needMicTesting) {
        const { uploadAudio, uploadText } =
          pluginMap[tu?.details?.type ?? ""] ?? {};
        if (uploadAudio && !uploadText) {
          needMicTesting = true;
        }
      }
    });
    console.log(
      `needMicTesting=${needMicTesting}, testMic=${!!experimentForEdit.testMic}`
    );
    if (needMicTesting && !experimentForEdit.testMic) {
      return confirm({
        variant: "error",
        title: "Microphone testing is required",
        description:
          "Some task units require audio input. Please enable microphone testing.",
      });
    }
    const firstTaskUnit = taskUnitData?.find(
      (p: TTaskUnit) => p.code === experimentForEdit.taskUnits[0]
    ) as TTaskUnit;
    const firstTaskUnitPluginMap =
      pluginMap[firstTaskUnit?.details?.type ?? ""] ?? {};

    const proceedSaving = async () => {
      try {
        await validateExperiment(experimentForEdit);
        setIsProcessing(true);
        fetch(
          createNew
            ? api.createExperiment({ projectId })
            : api.updateExperiment({ projectId }),
          {
            method: createNew ? "PUT" : "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
            },
            body: JSON.stringify(experimentForEdit),
          }
        )
          .then((res) => res.json())
          .then((json) => {
            console.log(json);
            if (!createNew) {
              if (
                !json ||
                !json[0] ||
                json[0].code !== experimentForEdit.code
              ) {
                throw new Error(
                  `Failed to save experiment "${experimentForEdit.code}" ${
                    json.message ?? ""
                  }`
                );
              } else {
                setIsProcessing(false);
                snackbar({
                  severity: "success",
                  message: `Saving experiment "${experimentForEdit.code}" successfully`,
                });
                // setExperimentForEdit(json[0] as TExperiment)
                setExperimentForEdit(null);
              }
            } else {
              if (!json || json.code !== experimentForEdit.code) {
                throw new Error(
                  `Failed to create experiment "${experimentForEdit.code}" ${
                    json.message ?? ""
                  }`
                );
              } else {
                setIsProcessing(false);
                snackbar({
                  severity: "success",
                  message: `Creating new experiment "${experimentForEdit.code}" successfully`,
                });
                setExperimentForEdit(null);
              }
            }
            refetchExperimentData();
          })
          .catch((err) => {
            setIsProcessing(false);
            confirm({
              variant: "error",
              title: `Error saving experiment "${experimentForEdit.code}"`,
              description:
                err?.toString() ??
                "Error saving experiment. Please try again later.",
              agreeButtonText: "OK",
            });
          });
      } catch (err) {
        // validation error
        setIsProcessing(false);
        confirm({
          variant: "error",
          title: "Error",
          description: (err as Error).message,
          agreeButtonText: "OK",
        });
      }
    };

    if (
      (firstTaskUnitPluginMap.useAudio || firstTaskUnitPluginMap.useVideo) &&
      !experimentForEdit.testMic
    ) {
      return confirm({
        variant: "danger",
        title: "First task auto-play restriction by browsers",
        description: (
          <Box>
            <Typography variant="body1" sx={{ mb: 1 }}>
              For technical reasons, please avoid that the first task of the
              experiment plays a video or audio file automatically.
              Participants' browsers may disable the auto-play. You may consider
              adding a task at the beginning of the experiment as an
              introductory page with certain{" "}
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                interactivity
              </Typography>{" "}
              (like clicking a button) to solve this problem.
            </Typography>
          </Box>
        ),
        agreeButtonText: "Still save",
        cancelButtonText: "Cancel",
      }).then(() => proceedSaving());
    } else {
      proceedSaving();
    }
  };

  const handleCancel = () => {
    confirm({
      variant: "danger",
      title: `Cancel experiment editing`,
      description: `Are you sure to cancel editing this experiment?`,
      cancelButtonText: "No, stay here",
      agreeButtonText: "Yes, please cancel",
    }).then(() => {
      setExperimentForEdit(null);
    });
  };

  const handleCreateExperiment = () => {
    console.log(`handleCreateExperiment`);
    setCreateNew(true);
    setExperimentForEdit({
      code: "",
      language: "",
      description: "",
      testMic: true,
      allowRestart: true,
      taskUnits: [],
      skipFlowControls: [],
      createdDate: new Date(),
      modifiedDate: new Date(),
    });
  };

  const handleExperimentFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExperimentFilter(event.target.value);
  };

  const handleExperimentFilterClear = () => {
    setExperimentFilter("");
  };

  const handleTaskFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTaskFilter(event.target.value);
  };

  const handleTaskFilterClear = () => {
    setTaskFilter("");
  };

  const filteredSortedExperiments = React.useMemo(() => {
    const experimentFilterKeywords = experimentFilter.trim().split(/\s+/);
    const taskFilterKeywords = taskFilter.trim().split(/\s+/);

    const filteredExperiments = experimentData?.filter(
      (v: TExperiment) =>
        !experimentFilter ||
        matchKeywords(
          [v.code, v.description, v.language],
          experimentFilterKeywords
        )
    );

    const filteredByTask = filteredExperiments?.filter(
      (v: TExperiment) =>
        !taskFilter ||
        matchKeywords([JSON.stringify(v.taskUnits)], taskFilterKeywords)
    );

    return filteredByTask?.sort(
      (a: TExperiment, b: TExperiment) =>
        moment(b.modifiedDate).valueOf() - moment(a.modifiedDate).valueOf()
    );
  }, [experimentData, experimentFilter, taskFilter]);

  const handleProjectChange = React.useCallback(
    (code: string) => {
      const exp = experimentData.find(
        (p: TExperiment) => p.code === code
      ) as TExperiment;
      if (!exp) {
        return;
      }
      setExperimentCodeForProjectChange(code);
      setOriginalProjectIds(exp.project ?? []);
      setIsProjectChangeOpen(true);
    },
    [experimentData]
  );

  const handleProjectChangeConfirm = async (projectCodes: string[]) => {
    console.log(`handleProjectChangeConfirm: ${JSON.stringify(projectCodes)}`);
    try {
      const json = await fetch(api.changeExperimentProject({ projectId }), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
        body: JSON.stringify({
          projectCodes,
          experimentCodes: [experimentCodeForProjectChange],
        }),
      }).then((res) => res.json());
      console.log(`response`);
      console.log(json);
      if (json.error) {
        throw new Error(json.message || json.error);
      }
      setIsProcessing(false);
      snackbar({
        severity: "success",
        message:
          "Project designation of experiment has been successfully updated.",
      });
      setExperimentCodeForProjectChange("");
      setOriginalProjectIds([]);
      setIsProjectChangeOpen(false);
      refetchExperimentData();
    } catch (err) {
      setIsProcessing(false);
      confirm({
        variant: "error",
        title: `Error changing project designation for experiment "${experimentCodeForProjectChange}"`,
        description:
          (err as Error)?.toString() ??
          "Error updating this experiment. Please check and try again.",
        agreeButtonText: "OK",
      });
    }
  };

  const disabled = React.useMemo(
    () => isLoading || isFetching || isProcessing,
    [isLoading, isFetching, isProcessing]
  );

  const projectFound = React.useMemo(() => {
    let found = false;
    if (
      signedInUserWithSetUser?.projectCodeInUse &&
      signedInUserWithSetUser?.projects &&
      signedInUserWithSetUser?.projects?.length > 0
    ) {
      if (
        signedInUserWithSetUser?.projects.find(
          (p) => p.code === signedInUserWithSetUser?.projectCodeInUse
        )
      ) {
        found = true;
      }
    }
    return found;
  }, [
    signedInUserWithSetUser?.projectCodeInUse,
    signedInUserWithSetUser?.projects,
  ]);

  return (
    <Paper elevation={0}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "experiment"]} />
      <Stack sx={{ mt: 2 }}>
        <Box sx={{ m: 1, ml: 4, mr: 4 }}>
          <ProjectInUseSelector readOnly={!!experimentForEdit} />
        </Box>
        <Box sx={{ mt: 4, ml: 4 }}>
          {experimentForEdit ? (
            <ItemTitleInEdit
              prefix={createNew ? `Create new experiment` : `Edit experiment`}
              itemTitle={experimentForEdit.code}
            />
          ) : (
            <Typography variant="h3">Experiments</Typography>
          )}
        </Box>
        {isError && (
          <Box sx={{ mt: 4, ml: 4, mr: 4, p: 4 }}>
            <ErrorDisplay error={(error as Error).toString()} />
          </Box>
        )}
        {projectFound &&
          !isError &&
          signedInUserWithSetUser?.accessRight?.canEditExperiments &&
          !experimentForEdit && (
            <Box
              display={"flex"}
              flexDirection="row"
              justifyContent={"space-between"}
              sx={{ ml: 4 }}
            >
              <Button startIcon={<AddIcon />} onClick={handleCreateExperiment}>
                Create a new experiment
              </Button>
            </Box>
          )}
        {!projectFound && (
          <Typography variant="body1" sx={{ ml: 4, fontStyle: "italic" }}>
            A project is required
          </Typography>
        )}
        {!isError &&
          experimentData &&
          !experimentForEdit &&
          experimentData.length === 0 && (
            <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
              <Typography variant="body1">No experiments found</Typography>
            </Box>
          )}

        {!isError &&
          experimentData &&
          experimentData.length > 0 &&
          !experimentForEdit && (
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"space-between"}
            >
              {!experimentForEdit && (
                <Box
                  display={"flex"}
                  flexDirection="row"
                  justifyContent={"space-between"}
                  sx={{ mt: 2, mb: 2, mr: 2 }}
                >
                  <Box
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    sx={{ mr: 2, mt: 1 }}
                  >
                    <Typography variant="h4">{` `}</Typography>
                  </Box>
                  <Box
                    flex="8"
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                  >
                    <Typography>{`Experiment (${filteredSortedExperiments.length})`}</Typography>
                    <TextField
                      margin="none"
                      size="small"
                      variant="standard"
                      value={experimentFilter}
                      onChange={handleExperimentFilterChange}
                      placeholder="Filter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleExperimentFilterClear}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    flex="7"
                    sx={{ ml: 2, mr: 0 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                  >
                    <Typography>Task units</Typography>
                    <TextField
                      margin="none"
                      size="small"
                      variant="standard"
                      value={taskFilter}
                      onChange={handleTaskFilterChange}
                      placeholder="Filter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTaskFilterClear}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              )}
              {!experimentForEdit &&
                filteredSortedExperiments?.map(
                  (p: TExperiment, pIndex: number) => (
                    <Box
                      key={`${p.code}-${pIndex}`}
                      display={"flex"}
                      flexDirection="row"
                      justifyContent={"space-between"}
                      sx={{ mb: 2, mr: 2 }}
                    >
                      <Box
                        flex="1"
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        sx={{ mr: 2, mt: 1 }}
                      >
                        <Typography variant="h4">{`${pIndex + 1}.`}</Typography>
                      </Box>
                      <Box flex="8">
                        <ExperimentCard
                          experiment={p as TExperiment}
                          onEdit={() => handleEdit(p.code)}
                          onCopy={() => handleCopy(p.code)}
                          onShare={() => handleShare(p.code)}
                          onDelete={() => handleDelete(p.code)}
                          canEditExperiments={
                            !!signedInUserWithSetUser?.accessRight
                              ?.canEditExperiments
                          }
                          canDeleteExperiments={
                            !!signedInUserWithSetUser?.accessRight
                              ?.canDeleteExperiments
                          }
                          {...(!!signedInUserWithSetUser?.accessRight
                            ?.canManageAllProjects && {
                            onProjectChange: () => handleProjectChange(p.code),
                          })}
                        />
                      </Box>
                      <Box flex="7" sx={{ ml: 2 }}>
                        {p.taskUnits.map((t, index) => {
                          const skipFlowControlEnabled =
                            !!p.skipFlowControls?.[index]?.enabled;
                          if (typeof t === "string") {
                            return (
                              <Chip
                                key={`${t}-${index}`}
                                label={t}
                                sx={{
                                  pl: 1,
                                  mr: 1,
                                  mb: 1,
                                  borderRadius: 2,
                                }}
                                {...(skipFlowControlEnabled && {
                                  icon: <SkipFlowControlIcon enabled={true} />,
                                })}
                              />
                            );
                          } else {
                            return (
                              <Chip
                                key={`${t.code}-${index}`}
                                label={t.code ?? "-"}
                                sx={{
                                  pl: 1,
                                  mr: 1,
                                  mb: 1,
                                  borderRadius: 2,
                                }}
                                {...(skipFlowControlEnabled && {
                                  icon: <SkipFlowControlIcon enabled={true} />,
                                })}
                              />
                            );
                          }
                        })}
                      </Box>
                    </Box>
                  )
                )}
            </Box>
          )}
        {!isError && experimentData && experimentForEdit && (
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-between"}
            sx={{ mr: 24 }}
          >
            <Box sx={{ ml: 4 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={4}
              >
                <Button startIcon={<ArrowBackIosIcon />} onClick={handleCancel}>
                  Back to experiment list
                </Button>
              </Box>
              <ExperimentEditor
                disabled={disabled}
                createNew={createNew}
                experiment={experimentForEdit}
                onChange={handleExperimentChange}
              />
            </Box>
          </Box>
        )}
        {disabled && !error && <CurtainWithProgress open={disabled} />}
        {!!experimentForEdit && (
          <React.Fragment>
            <Fab
              color="warning"
              aria-label="cancel"
              variant="extended"
              onClick={handleCancel}
              sx={{ position: "fixed", bottom: 96, right: 32 }}
            >
              <CancelIcon sx={{ mr: 1 }} />
              Cancel
            </Fab>
            <Fab
              color="primary"
              aria-label="save"
              variant="extended"
              onClick={handleSaveEdited}
              sx={{ position: "fixed", bottom: 32, right: 32 }}
            >
              <SaveIcon sx={{ mr: 1 }} />
              Save
            </Fab>
          </React.Fragment>
        )}
      </Stack>
      <ProjectChanger
        show={isProjectChangeOpen}
        onClose={() => setIsProjectChangeOpen(false)}
        onCancel={() => setIsProjectChangeOpen(false)}
        selectedProjectIds={originalProjectIds}
        title={`Change project designation for ${experimentCodeForProjectChange}`}
        helperText={
          "Designate the projects that will have access to this experiment"
        }
        onConfirm={handleProjectChangeConfirm}
      />
      {/*<SpeedDial
        ariaLabel={"Save Changes"}
        icon={<SpeedDialIcon icon={<SettingsIcon />} openIcon={<SettingsTwoToneIcon />} />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        direction={"up"}
        hidden={!experimentForEdit}
        sx={{
          position: "fixed",
          "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: 32,
            right: 32,
          },
          "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            top: 32,
            left: 32,
          },
        }}
      >
        <SpeedDialAction
          icon={<SaveIcon />}
          tooltipTitle={"Save Changes"}
          tooltipOpen
          onClick={handleSaveEdited}
        />
        <SpeedDialAction
          icon={<CancelIcon />}
          tooltipTitle={"Cancel"}
          tooltipOpen
          onClick={() => setExperimentForEdit(null)}
        />
      </SpeedDial>*/}
    </Paper>
  );
}

export default ExperimentPage;
