import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { Box, Stack, Typography } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import doldTwLogo from "../assets/images/dold.tw-logo.png";
import { site } from "../resources/config";

const SiteLogo = ({ isBanner = false }: { isBanner?: boolean }) => {
  return site === "eduhk" ? (
    isBanner ? (
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around"
        flexWrap={"nowrap"}
      >
        <TipsAndUpdatesIcon
          sx={{
            // display: { xs: "none" },
            mr: 1,
            color: yellow[800],
          }}
        />
        <Typography
          variant="h6"
          noWrap
          sx={{
            mr: 2,
            // display: { xs: "none" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: grey[900],
            textDecoration: "none",
          }}
        >
          DOLD
        </Typography>
      </Stack>
    ) : null
  ) : (
    <Box>
      <img
        src={doldTwLogo}
        alt="DOLD.TW"
        style={{ height: isBanner ? 80 : "10vmin" }}
      />
    </Box>
  );
};

export default SiteLogo;
