import { getFlag } from "../libraries/helper";
import languageCodes from "../resources/languageCodes.json";

export const languages = [
  {
    id: "cantonese",
    label: "Cantonese",
  },
  {
    id: "putonghua",
    label: "Putonghua",
  },
  {
    id: "english",
    label: "English",
  },
];

export const lexicon: {
  [term: string]: {
    [language: string]: string;
  };
} = {
  language: {
    cantonese: "粵語 Cantonese",
    putonghua: "普通話 Putonghua",
    english: "英語 English",
  },
  userIdOrEmail: {
    cantonese: "用戶編號或電郵地址",
    putonghua: "用戶編號或電郵地址",
    english: "User ID or email address",
  },
  enterUserIdOrEmailNote: {
    cantonese: "請輸入你的用戶編號或電郵地址",
    putonghua: "請輸入你的用戶編號或電郵地址",
    english: "Please enter your user ID or email address",
  },
  letsStart: {
    cantonese: "開始啦",
    putonghua: "開始啦",
    english: `Let's start!`,
  },
  selectRecordingDeviceNote: {
    cantonese: "選擇錄音設備",
    putonghua: "選擇錄音設備",
    english: "Select recording equipment",
  },
  selectThisDeviceNote: {
    cantonese: "選擇這設備",
    putonghua: "選擇這設備",
    english: "Select this device",
  },
  testMicNote: {
    cantonese: "開始之前，我地試下錄音先，你可以試下講「我 想 講 故 仔」",
    putonghua: "開始之前，我們先試試錄音，你可以試講「我 想 講 故 事」",
    english: `Before we start, let's test the recording. You can try to say "I - like - story - telling".`,
  },
  testMicRecordingNote: {
    cantonese: "我而家聽緊你講啦！你可以試下講「我 想 講 故 仔」",
    putonghua: "我在聽你的說話！你可以試講「我 想 講 故 事」",
    english: `I'm listening to you now. You can try to say "I - like - story - telling".`,
  },
  listeningNote: {
    cantonese: "聽取錄音",
    putonghua: "聽取錄音",
    english: "Listen to the recording",
  },
  playingRecordingNote: {
    cantonese: "現正播放剛才的錄音，你能聽到嗎？",
    putonghua: "現正播放剛才的錄音，你能聽到嗎？",
    english: "The recording is playing now. Can you hear it?",
  },
  recordingOKNote: {
    cantonese: "聽得到",
    putonghua: "聽得到",
    english: "Yes, the recording is clear.",
  },
  reselectRecordingDevice: {
    cantonese: "重新選擇麥克風",
    putonghua: "重新選擇麥克風",
    english: "Reselect the microphone",
  },
  startOver: {
    cantonese: "重新開始",
    putonghua: "重新開始",
    english: "Start over",
  },
  startOverConfirmNote: {
    cantonese: "要重新開始嗎？",
    putonghua: "要重新開始嗎？",
    english: "Do you want to start over?",
  },
  startOverConfirmOK: {
    cantonese: "是的，重新開始",
    putonghua: "是的，重新開始",
    english: "Confirm to start over",
  },
  startOverCancel: {
    cantonese: "取消，不要重新開始",
    putonghua: "取消，不要重新開始",
    english: `Cancel, don't start over`,
  },
  taskDone: {
    cantonese: "完成喇",
    putonghua: "完成了",
    english: "Done!",
  },
  taskDoneThanks: {
    cantonese: "多謝你！",
    putonghua: "謝謝！",
    english: `Thank you!`,
  },
  languageBigLabel: {
    cantonese: "粵",
    putonghua: "普",
    english: "英",
    others: "\u2013",
  },
  testRecordingMedia: {
    cantonese: "media/ctnTestMic.m4a",
    putonghua: "media/pthTestMic.m4a",
    english: "media/engTestMic.m4a",
  },
};

export const renderTerm = (languageCode: string = "", term: string = "") =>
  lexicon[term]?.[languageCode] ?? lexicon[term]?.others ?? null;

export const renderLanguageName = (languageCode: string = "") => {
  const name = lexicon["language"]?.[languageCode];
  if (name) {
    return name;
  }

  const language = languageCodes.find((v) => v.code === languageCode);
  return language?.label ?? null
};

export const renderBigLabel = (languageCode: string = "") => {
  const label = lexicon["languageBigLabel"]?.[languageCode];
  if (label) {
    return label;
  }

  const flag = getFlag(languageCode);
  return flag || lexicon["languageBigLabel"]?.others;
};
