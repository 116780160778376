import short from "short-uuid";

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const flatten: any = (obj: any, path = "") => {
  if (!(obj instanceof Object)) return { [path.replace(/\.$/g, "")]: obj };

  return Object.keys(obj).reduce((output, key: any) => {
    return obj instanceof Array
      ? { ...output, ...flatten(obj[key] as any, path + "[" + key + "].") }
      : { ...output, ...flatten(obj[key], path + key + ".") };
  }, {});
};

// export const matchAny = (source: string = '', arr: string[]) => arr.some((v: string) => source.includes(v))

export const matchKeywords = (
  sources: string[] = [],
  matches: string[] = [],
  isCaseSensitive: boolean = false
) =>
  matches.every((m) =>
    sources.some((s) =>
      (isCaseSensitive ? s : s?.toUpperCase())?.includes(
        isCaseSensitive ? m : m?.toUpperCase()
      )
    )
  );

export const extractMedia = (stimulus: string = "") => {
  const regex =
    /<video[^>]*>.*?<source[^>]*src=['"]([^'">]*)['"][^>]*?>.*?<\/video>|<img[^>]*src=['"]([^'">]*)['"][^>]*>|<audio[^>]*>.*?<source[^>]*src=['"]([^'">]*)['"][^>]*?>.*?<\/audio>/gi;
  const images = [];
  const videos = [];
  const audio = [];
  let match;
  do {
    match = regex.exec(stimulus);
    if (match?.[1]) {
      videos.push(match[1]);
    } else if (match?.[2]) {
      images.push(match[2]);
    } else if (match?.[3]) {
      audio.push(match[3]);
    }
  } while (match);

  if (isValidUrl(stimulus)) {
    if (stimulus.match(/\.(jpeg|jpg|gif|png)$/i)) {
      images.push(stimulus);
    } else if (stimulus.match(/\.(wav|mp3|ogg|aac|m4a)$/i)) {
      audio.push(stimulus);
    } else if (stimulus.match(/\.(mp4|avi|mov|wmv|mkv)$/i)) {
      videos.push(stimulus);
    }
  }

  return {
    images,
    videos,
    audio,
  };
};

export const getStimulusType = (stimulus: string = "") => {
  const { images, videos, audio } = extractMedia(stimulus);
  if (images.length > 0) {
    return "image";
  } else if (videos.length > 0) {
    return "video";
  } else if (audio.length > 0) {
    return "audio";
  } else {
    return "";
  }
};

export const extractAllMedia = (stimulus: string | string[]) => {
  if (typeof stimulus === "string") {
    return extractMedia(stimulus);
  } else if (stimulus instanceof Array) {
    const allImages: string[] = [];
    const allVideos: string[] = [];
    const allAudio: string[] = [];
    stimulus.forEach((s) => {
      const { images, videos, audio } = extractMedia(s);
      allImages.push(...images);
      allVideos.push(...videos);
      allAudio.push(...audio);
    });
    return {
      images: allImages,
      videos: allVideos,
      audio: allAudio,
    };
  } else {
    return {
      images: [],
      videos: [],
      audio: [],
    };
  }
};

function applyAutoMaxSingle(stimulus: string = "") {
  const tempDiv = document.createElement("div");

  // Set the innerHTML of the tempDiv to the HTML string
  tempDiv.innerHTML = stimulus.trim();

  // Get the first element inside the tempDiv (e.g., <video> or <img>)
  const mediaElement = tempDiv.firstChild as HTMLElement | null;

  // Check if the element is a video or img
  if (
    mediaElement instanceof HTMLVideoElement ||
    mediaElement instanceof HTMLImageElement
  ) {
    // Only add the class if no "width" or "height" attribute is set
    if (
      !mediaElement.hasAttribute("width") &&
      !mediaElement.hasAttribute("height")
    ) {
      // Check if the element already has the 'auto-max' class
      if (!mediaElement.classList.contains("auto-max")) {
        mediaElement.classList.add("auto-max");
      }
    }
  } else {
    console.error("The provided HTML string is not a video or image tag.");
    return stimulus; // Return the original string if it's not a valid media element
  }

  // Return the modified HTML string
  return tempDiv.innerHTML;
  /*
  if (stimulus.match(/<video\b|<img\b/i) && !stimulus.match(/width=|height=/)) {
    // Create a temporary div to hold the HTML string
    const tempDiv = document.createElement("div");

    // Set the innerHTML of the tempDiv to the HTML string
    tempDiv.innerHTML = stimulus.trim();

    // Get the first element inside the tempDiv (e.g., <video> or <img>)
    const mediaElement = tempDiv.firstChild;

    // Check if the element is a video or img
    if (
      mediaElement instanceof HTMLVideoElement ||
      mediaElement instanceof HTMLImageElement
    ) {
      if (!mediaElement.hasAttribute('width') && !mediaElement.hasAttribute('height')) {
        // Check if the element already has the 'auto-max' class
        if (!mediaElement.classList.contains('auto-max')) {
            mediaElement.classList.add('auto-max');
        }
    }
    } else {
      console.error("The provided HTML string is not a video or image tag.");
      return stimulus; // Return the original string if it's not a valid media element
    }

    // Return the modified HTML string
    return tempDiv.innerHTML;
  } else {
    return stimulus;
  }
    */
}

export const applyAutoMax = (stimulus: string | string[] = "") => {
  console.log(`applyAutoMax(): stimulus = ${JSON.stringify(stimulus)}`);
  if (stimulus instanceof Array) {
    const result = stimulus.map((s) => applyAutoMaxSingle(s));
    console.log(`applyAutoMax(): result = ${JSON.stringify(result)}`);
    return result;
  } else {
    const result = applyAutoMaxSingle(stimulus);
    console.log(`applyAutoMax(): result = ${JSON.stringify(result)}`);
    return result;
  }
};

export const renderAsHTML = ({
  type = "",
  url = "",
  options,
}: {
  type: string;
  url: string;
  options?: {
    mediaWidth?: number;
    mediaHeight?: number;
    mediaPrompt?: string;
  };
}) => {
  console.log(
    `renderAsHTML(): type=${type}, url=${url}, options=${JSON.stringify(
      options
    )}`
  );
  var html;
  if (type === "video") {
    if (options?.mediaWidth || options?.mediaHeight) {
      html = `<video autoplay ${
        typeof options?.mediaWidth === "number"
          ? `width="${options.mediaWidth}"`
          : ""
      } ${
        typeof options?.mediaHeight === "number"
          ? `height="${options.mediaHeight}"`
          : ""
      }><source src='${url}'/></video>`;
    } else {
      html = `<video autoplay class="dold-video"><source src='${url}'/></video>`;
    }
  } else if (type === "audio") {
    html = `<audio autoplay><source src='${url}'/></audio>`;
  } else if (type === "image") {
    if (options?.mediaWidth || options?.mediaHeight) {
      html = `<img src='${url}' ${
        typeof options?.mediaWidth === "number"
          ? `width="${options.mediaWidth}"`
          : ""
      } ${
        typeof options?.mediaHeight === "number"
          ? `height="${options.mediaHeight}"`
          : ""
      }/>`;
    } else {
      html = `<img src='${url}'/>`;
    }
  } else {
    html = "";
  }
  if (options?.mediaPrompt) {
    html += `<p class="dold-media-prompt">${options?.mediaPrompt}</p>`;
  }
  console.log(`renderAsHTML(): html = ${html}`);
  return html;
};

export const canonize = (input = "") => {
  return input
    .replace(/[^\w\d_-]+/g, "")
    .replace(/^-/, "")
    .toLowerCase();
};

export const generateRandomSuffix = (prefix?: string) => {
  const uuid = short.generate();
  const suffix = uuid.slice(0, 4) + uuid.slice(-4);
  return prefix ? `${prefix}.${suffix}` : suffix;
};

export const getFlag = (languageCode: string) => {
  // languageCode = "en-US", "yue-Hant-HK", etc.
  const splits = languageCode.split("-");

  if (splits.length > 1) {
    const countryCode = splits.pop();
    const codePoints = countryCode
      ? countryCode
          .toUpperCase()
          .split("")
          .map((char) => 127397 + char.charCodeAt(0))
      : [];
    return String.fromCodePoint(...codePoints);
  } else {
    return null;
  }
};

export const downloadFile = (data = "", filename = "list.txt") => {
  const dataBlob = new Blob([data], { type: "text/plain" });
  const dataUrl = window.URL.createObjectURL(dataBlob);
  const downloadLink = document.createElement("a");
  downloadLink.setAttribute("href", dataUrl);
  downloadLink.setAttribute("download", filename);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const formatBytes = (bytes = 0, decimals = 0) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const interpretDiskSize = (sizeStr: string) => {
  const regex = /^(\d+(?:\.\d+)?)([KMGTP]?B?)$/i;
  const match = sizeStr.match(regex);

  if (!match) {
    return 0;
  }

  // Extract the value and the unit
  const value = parseFloat(match[1]);
  const unit = match[2].toUpperCase();

  // Conversion factors for different units
  const units = {
    B: 1,
    K: 1024,
    M: 1024 ** 2,
    G: 1024 ** 3,
    T: 1024 ** 4,
    P: 1024 ** 5,
    E: 1024 ** 6,
    Z: 1024 ** 7,
    Y: 1024 ** 8,
  };

  // Determine the factor based on the unit
  const factor = units[unit[0] as keyof typeof units] || 1;

  // Calculate the size in bytes
  return Math.floor(value * factor);
};
